import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { getItem } from './utils/persistenceUtils'
import { TOKEN_KEY } from './utils/constants'

import { defaultsHeadersAxios } from './services/api'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const token = getItem(TOKEN_KEY)

defaultsHeadersAxios(token || '')

root.render(<App />)
