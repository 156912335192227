import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { useCompanies } from './useCompanies'
import api from '../services/api'
import { MODALIDADES } from '../utils/constants'
import { formatDateTimeStampAsX } from '../utils/formatDate'

const ProposalProgressContext = createContext({} as any)

const ProposalProgressProvider = ({ children }: PropsWithChildren) => {
  const { company } = useCompanies()
  const [propostas, setPropostas] = React.useState<any>([])
  const [showDetailsByIdProposal, setShowDetailsByIdProposal] = useState<any>(null)
  const getListProposalCadastradas = async () => {
    try {
      const {
        data: { proposals },
      } = await api.get(`proposal/all/by_company/${company?.id}?page=${0}&status_id=6,7,8,9,10,11,12`)
      setPropostas(
        proposals.map((proposta: any) => ({
          ...proposta,
          modality: MODALIDADES.find(item => item.id === proposta.modality)?.value || '----------------',
          dispute_date_timestamp: proposta.dispute_date,
          dispute_date: formatDateTimeStampAsX(proposta.dispute_date),
        }))
      )
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <ProposalProgressContext.Provider value={{ getListProposalCadastradas, propostas, showDetailsByIdProposal }}>
      {children}
    </ProposalProgressContext.Provider>
  )
}

const useProposalProgress = () => {
  return useContext(ProposalProgressContext)
}

export { ProposalProgressProvider, useProposalProgress }
