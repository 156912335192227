import { useEffect, useState } from 'react'
import api from 'services/api'

interface props {
  url: string
  page?: number
  active?: boolean
}

export function useFetch<T = unknown>({ url, page, active = true }: props) {
  const [data, setData] = useState<T | null>(null)
  const [loading, setLoading] = useState(true)
  const [isFetching, setFetching] = useState(false)
  const [error, setError] = useState(false)

  const getApi = async () => {
    try {
      setFetching(true)
      setLoading(true)
      const { data } = await api.get<T>(url)
      await setData(data)
    } catch (e: any) {
      setError(true)
    } finally {
      setLoading(false)
      setFetching(false)
    }
  }

  useEffect(() => {
    if (active) {
      getApi()
    }
  }, [page, active, url])

  return { data, loading, error, isFetching }
}
