import styled from 'styled-components'
import { theme } from 'styles'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100%;

  svg {
    width: 52px;
    height: 52px;
  }

  .text-box {
    display: flex;
    align-items: center;
    gap: 12px;

    h2 {
      font-size: 18px;
    }

    a {
      all: unset;
      cursor: pointer;
      color: ${theme.colors.cyan40};
      font-size: 18px;
    }
  }

  .not-found-video {
    width: 25%;

    img {
      width: 100%;
    }
  }
`
