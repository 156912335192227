import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import api from '../services/api'
import { useCompanies } from './useCompanies'
import { useFetch } from './useFetch'

interface BidStatusContextProps {
  handleBidStatusId: any
  bidStatus: any
  shortStatus: any
  handleBidStatusIdStatus: any
}
export interface BidStatus {
  bid_status_type: number
  color: string
  description: string
  id: number
  monitor_chat: boolean
  monitor_status: boolean
  portal: number
}

export interface BidStatusType {
  id: number
  type: string
  value: string
}

const BidStatusContext = createContext<BidStatusContextProps>({} as BidStatusContextProps)

const BidStatusProvider = ({ children }: PropsWithChildren) => {
  const [bidStatus, setBidStatus] = useState<BidStatus[]>([])
  const [shortStatus, setShortStatus] = useState<BidStatusType[]>([])

  const { company } = useCompanies()
  const { data } = useFetch<BidStatus[]>({
    url: `monitoring/bid/status`,
    active: company,
  })

  const getBindsStatus = async () => {
    try {
      const { data } = await api.get<{ id: number; type: string }[]>(`monitoring/bid/status/type`)
      setShortStatus(
        data.map(el => {
          return {
            ...el,
            value: el.type,
          }
        })
      )
    } catch (e) {
      console.log(e)
    }
  }

  const handleBidStatusId = (id: number) => {
    return bidStatus.filter(bid => bid.id === id)
  }

  const handleBidStatusIdStatus = (id: number) => {
    const bid = handleBidStatusId(id)
    return bid[0]?.description
  }

  useEffect(() => {
    if (data) {
      getBindsStatus()
      setBidStatus(data)
    }
  }, [data])

  return (
    <BidStatusContext.Provider value={{ handleBidStatusId, bidStatus, shortStatus, handleBidStatusIdStatus }}>
      {children}
    </BidStatusContext.Provider>
  )
}

const useBidStatus = () => {
  return useContext(BidStatusContext)
}

export { BidStatusProvider, useBidStatus }
