import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { useLocation, useMatch } from 'react-router-dom'
import api from '../services/api'
import { routes } from '../utils/constants'
import { OccurrenceDay } from '../types/occurrenceDay'
import handleMessageError from '../utils/handleMessageError'
import { useCompanies } from './useCompanies'
import { useToast } from './useToast'
import useToggle from './useToggle'

const OccurrenceDayContext = createContext<any>(null)

const OccurrenceDayProvider = ({ children }: PropsWithChildren) => {
  const { isOpen: loading, openToggle, closeToggle } = useToggle()

  const { company } = useCompanies()
  const [inputValue, setInputValue] = useState<any>({})
  const [companiesId, setCompaniesId] = useState<any>(false)
  const [messages, setMessages] = useState<any>([])
  const [endOfList, setEndOfList] = useState<boolean>(false)
  const [totalMessages, setTotalMessages] = useState<number>(0)
  const [totalMessagesNotRead, setTotalMessagesNotRead] = useState<number>(0)
  const monitoringOccurrencesMatch = useMatch(routes.MONITORING_OCCURRENCES)

  const {
    isOpen: updateFirebaseOccurrence,
    openToggle: activeFirebaseOccurrence,
    closeToggle: disableFirebaseOccurrence,
  } = useToggle()
  const [soundOccurrence, setSoundOccurrence] = useState<any>(true)
  const { toastError } = useToast()

  const getOcurrence = async (page: any) => {
    try {
      openToggle()
      const { data } = await api.get(
        `monitoring/messages/all/matched/today?companies=${companiesId || company?.id}&page=${page}`,
        inputValue
      )
      handleChangeMessage({ data, page })
    } catch (e: any) {
      const messageError = Object.values(e.response.data).toString()
      toastError('Erro na busca de ocorrencias', messageError)
    } finally {
      closeToggle()
    }
  }

  useEffect(() => {
    if (company) {
      if (!companiesId && monitoringOccurrencesMatch) {
        setCompaniesId(company.id)
      }
    }
  }, [monitoringOccurrencesMatch])

  const toggleSoundOccurrence = () => {
    setSoundOccurrence(!soundOccurrence)
  }

  const handleChangeCompany = useCallback(() => {
    if (company && !companiesId) {
      setCompaniesId([company?.id])
    }
  }, [company])

  useEffect(() => {
    handleChangeCompany()
  }, [company])

  const handleValues = (target: any) => {
    const { id, value } = target
    if (value.length > 0) {
      const newValues = { ...inputValue, [id]: value }
      setInputValue(newValues)
      const companies_id = newValues.companies_id?.map((item: any) => item.id)
      setCompaniesId(companies_id?.toString())
    } else {
      resetState()
    }
  }

  const resetState = () => {
    setInputValue({})
    setCompaniesId(false)
    setMessages([])
    setEndOfList(false)
    setTotalMessages(0)
    setTotalMessagesNotRead(0)
  }

  const handleChangeMessage = ({ data, page }: any) => {
    if (page === 0) {
      setTotalMessagesNotRead(data?.total_messages_not_read)
      setTotalMessages(data?.total_messages)
      setMessages(data?.messages)
      setEndOfList(false)
    } else {
      if (data?.messages.length === 0) {
        setEndOfList(true)
      }
      // eslint-disable-next-line no-unsafe-optional-chaining
      setMessages([...messages, ...data?.messages])
    }
  }

  const handleMarkAllRead = async () => {
    checkMessageAllRead()
    if (companiesId) {
      const company = JSON.parse(`[${companiesId}]`)
      try {
        await api.post(`monitoring/messages/mark/matched/today`, { companies: company })
      } catch (e: any) {
        console.log('Erro ao buscar licitações.', handleMessageError(e))
      }
    }
  }
  const checkMessageAllRead = () => {
    setTotalMessagesNotRead(0)
    const newMessages = messages.map((item: OccurrenceDay) => {
      return { ...item, is_read: true }
    })
    setMessages(newMessages)
  }

  const checkMessage = async (id: number) => {
    setTotalMessagesNotRead(totalMessagesNotRead - 1)
    const newMessages = messages.map((item: OccurrenceDay) => {
      if (item.id === id) {
        return { ...item, is_read: true }
      }
      return item
    })
    setMessages(newMessages)
  }

  const updateMessageBiddingMatched = async (id: number, bidding: any) => {
    await checkMessage(id)
    try {
      await api.patch(`monitoring/messages/mark_message_as_read/${bidding}`, { messages_id: [id] })
    } catch (e: any) {
      console.log('Erro ao buscar licitações.', handleMessageError(e))
    }
  }

  return (
    <OccurrenceDayContext.Provider
      value={{
        totalMessages,
        totalMessagesNotRead,
        companiesId,
        handleChangeCompany,
        handleValues,
        inputValue,
        loading,
        handleChangeMessage,
        activeFirebaseOccurrence,
        disableFirebaseOccurrence,
        updateMessageBiddingMatched,
        handleMarkAllRead,
        updateFirebaseOccurrence,
        soundOccurrence,
        getOcurrence,
        toggleSoundOccurrence,
        messages,
        endOfList,
      }}
    >
      {children}
    </OccurrenceDayContext.Provider>
  )
}

const useOccurrenceDay = () => {
  return useContext(OccurrenceDayContext)
}

export { OccurrenceDayProvider, useOccurrenceDay }
