export const emptyInput = (inputValue: any) => Object.values(inputValue).every(value => value)

export const validetValueError = (inputValue: any) => {
  let array = {}
  Object.entries(inputValue).map((el: any) => {
    array = Object.assign(array, {
      [el[0]]: el[1]?.length === 0 || el[1] === null || el[1] === '' || el[1] === undefined || el[1] === false,
    })
    return null
  })
  return array
}
