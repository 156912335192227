import { MockInputCompanie } from 'Mocks/ProposalSettingsMock'
import { createContext, PropsWithChildren, useContext, useState } from 'react'
import api from 'services/api'
import { useCompanies } from './useCompanies'
import { useToast } from './useToast'

export interface SettingsProposalContextProps {
  signature: any
  activeHeading: boolean
  changeActiveHeading: ({ active }: { active: boolean }) => void
  inputValue: any
  setSignature: any
  handleValues: any
  handleSaveChanges: any
  activeGeneratingPdf: boolean
  changeActiveGeneratePdf: (active: boolean) => void
  getHeaderSetup: () => void
  updateHeaderSetup: () => void
  getConfigHeader: () => void
  getFooterSetup: () => void
  inputFooter: any
  handleValuesFooter: any
  updateFooterSetup: () => void
  setFileLogo: (file: any) => void
  setFileSignature: (file: any) => void
  getConfigProposal: () => void
  fileLogo: any
  updateLogo: (dataFileLogo: any) => void
  updateSignature: (file: any) => void
}

interface proposalHeaderProps {
  cell_phone?: string
  city?: string
  cnpj?: string
  company: number
  complement?: string
  corporate_name?: string
  email?: string
  enable: boolean
  id: number
  municipal_registration?: string
  neighborhood: null
  phone?: string
  state_registration?: string
  street?: string
  street_number?: string
  uf?: string
  website?: string
  zip_code?: string
}

const MokeFooter = {
  bank_data: '',
  cpf: '',
  expiration_date: '',
  obs: '',
  responsible_name: '',
  rg: '',
}

const SettingsProposal = createContext<SettingsProposalContextProps>({
  signature: null,
  activeHeading: true,
  changeActiveHeading: () => null,
  inputValue: MockInputCompanie,
  setSignature: () => null,
  handleValues: () => null,
  handleSaveChanges: () => null,
  activeGeneratingPdf: true,
  changeActiveGeneratePdf: () => null,
  getHeaderSetup: () => null,
  updateHeaderSetup: () => null,
  getConfigHeader: () => null,
  getFooterSetup: () => null,
  inputFooter: MokeFooter,
  handleValuesFooter: () => null,
  updateFooterSetup: () => null,
  fileLogo: null,
  setFileLogo: () => null,
  setFileSignature: () => null,
  getConfigProposal: () => null,
  updateLogo: (dataFileLogo: any) => null,
  updateSignature: (file: any) => null,
})

const SettingsProposalProvider = ({ children }: PropsWithChildren) => {
  const [fileLogo, setFileLogo] = useState<any>(null)
  const [fileSignature, setFileSignature] = useState<any>(null)
  const [activeHeading, setActiveHeading] = useState(true)
  const [activeGeneratingPdf, setActiveGeneratingPdf] = useState(true)
  const [signature, setSignature] = useState<any>()
  const [inputValue, setInputValue] = useState(MockInputCompanie)
  const [inputFooter, setInputFooter] = useState(MokeFooter)
  const { toastSuccess, toastError } = useToast()
  const { company } = useCompanies()

  const handleValues = (target: any) => {
    const { id, value } = target
    setInputValue({ ...inputValue, [id]: value })
  }

  const handleValuesFooter = (target: any) => {
    const { id, value } = target
    setInputFooter({ ...inputFooter, [id]: value })
  }

  const handleSaveChanges = () => {
    console.log('saved', {
      ...inputValue,
      signature,
    })
  }

  const changeActiveHeading = ({ active }: { active: boolean }) => {
    setActiveHeading(active)
    setInputValue({ ...inputValue, enable: active })
  }

  const changeActiveGeneratePdf = (active: boolean) => {
    setActiveGeneratingPdf(active)
  }

  const getConfigProposal = async () => {
    getConfigHeader()
    getHeaderSetup()
    getFooterSetup()
  }

  const getConfigHeader = async () => {
    try {
      const { data } = await api.get(`proposal/company/pdf_settings/${company?.id}`)
      setFileLogo(data?.proposal_pdf_settings_default?.url_logo_pdf)
      setSignature(data?.proposal_pdf_settings_default?.url_signature_pdf)
    } catch {
      console.log('Erro')
    }
  }

  const updateConfigHeader = async () => {
    try {
      await api.patch(`proposal/company/pdf_settings/${company?.id}`, {
        gen_pdf: activeGeneratingPdf,
      })
    } catch (error) {
      toastError('Erro ao atualizar dados', 'error')
    }
  }

  const getHeaderSetup = async () => {
    try {
      const { data } = await api.get(`/proposal/company/setup_header/${company?.id}`)
      setInputValue(data?.proposal_setup_header_default)
      setActiveHeading(data?.proposal_setup_header_default?.enable)
    } catch {
      console.log('Erro')
    }
  }

  const updateHeaderSetup = async () => {
    await updateConfigHeader()
    await updateFooterSetup()
    try {
      await api.patch(`/proposal/company/setup_header/${company?.id}`, {
        ...inputValue,
      })
      toastSuccess('Dados atualizados com sucesso', '')
    } catch (error) {
      toastError('Erro ao atualizar dados', 'error')
    }
  }

  const getFooterSetup = async () => {
    try {
      const {
        data: { proposal_setup_footer_default },
      } = await api.get(`/proposal/company/setup_footer/${company?.id}`)

      setInputFooter({
        ...proposal_setup_footer_default,
        expiration_date: new Date(proposal_setup_footer_default.expiration_date).toISOString().split('T')[0],
      })
    } catch {
      console.log('Erro')
    }
  }

  const updateFooterSetup = async () => {
    const newDate = new Date(`${inputFooter.expiration_date} GMT-0300`).getTime()
    try {
      await api.patch(`/proposal/company/setup_footer/${company?.id}`, {
        ...inputFooter,
        expiration_date: newDate,
      })
    } catch (erro) {
      toastError('Erro ao atualizar dados', 'error')
    }
  }

  const updateLogo = async (dataFileLogo: any) => {
    try {
      const formData = new FormData()
      formData.append('file', dataFileLogo)
      await api.post(`/proposal/company/upload/logo/${company?.id}`, formData)
      setFileLogo(dataFileLogo)
    } catch (error) {
      toastError('Erro ao atualizar dados', 'error')
    }
  }

  const updateSignature = async (file: any) => {
    try {
      const formData = new FormData()
      formData.append('file', fileSignature)
      await api.post(`/proposal/company/upload/signature/${company?.id}`, formData)
      setFileSignature(file)
    } catch (error) {
      toastError('Erro ao atualizar dados', 'error')
    }
  }

  return (
    <SettingsProposal.Provider
      value={{
        fileLogo,
        signature,
        inputValue,
        setSignature,
        handleValues,
        activeHeading,
        changeActiveHeading,
        handleSaveChanges,
        activeGeneratingPdf,
        changeActiveGeneratePdf,
        getHeaderSetup,
        updateHeaderSetup,
        getConfigHeader,
        getFooterSetup,
        inputFooter,
        handleValuesFooter,
        updateFooterSetup,
        setFileLogo,
        setFileSignature,
        getConfigProposal,
        updateLogo,
        updateSignature,
      }}
    >
      {children}
    </SettingsProposal.Provider>
  )
}

const useSettingsProposal = () => {
  return useContext(SettingsProposal)
}

export { SettingsProposalProvider, useSettingsProposal }
