import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import api from '../services/api'
import { STATES } from '../utils/constants'
import { formatDateBr } from '../utils/formatDate'
import { usePortal } from './userPortal'

export interface PropspectionSearchFilter {
  portal: string
  states: string
  modality: string
  start_date: string
  end_date: string
  keyword: string
  offset: number
}

export interface PropspectionSearchProps {
  portal: { id: number; name: string; check: boolean }[]
  states: string
  modality: string[]
  filters: PropspectionSearchFilter
  handleFilters: <K extends keyof PropspectionSearchFilter>(key: K, value: PropspectionSearchFilter[K]) => void
  handleApiModality: () => Promise<void>
}

const PropspectionSearchContext = createContext<PropspectionSearchProps>({} as PropspectionSearchProps)

const PropspectionSearchProvider = ({ children }: PropsWithChildren) => {
  const [filters, setFilters] = useState({
    portal: '',
    states: '',
    modality: '',
    start_date: formatDateBr(new Date()).toString(),
    end_date: formatDateBr(new Date()).toString(),
    keyword: '',
    offset: 0,
  })
  const [modality, setModality] = useState([])
  const [portal, setPortal] = useState<{ id: number; name: string; check: boolean }[]>([])
  const [states, setStates] = useState<any>(null)
  const { portalsAll } = usePortal()

  const handleApiModality = async () => {
    try {
      const { data } = await api.get(`prospection/modalities`)
      const newModality = data
        .map((el: any) => {
          return { ...el, check: true }
        })
        .sort((a: any, b: any) => a.name.localeCompare(b.name))
      setModality(newModality)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (portalsAll && modality) {
      const newPortal = portalsAll?.map((el: any) => ({ ...el, check: true }))
      const newState = STATES.map((el: any) => ({ ...el, id: el.uf, check: true }))
      setPortal(newPortal)
      setStates(newState)
      setFilters({
        ...filters,
        portal: newPortal?.map((el: any) => el.id).toString(),
        states: newState?.map((el: any) => el.uf).toString(),
        modality: modality?.map((el: any) => el.id).toString(),
      })
    }
  }, [portalsAll, modality])

  const handleFilters = <K extends keyof PropspectionSearchFilter>(key: K, value: PropspectionSearchFilter[K]) => {
    setFilters({ ...filters, [key]: value })
  }

  return (
    <PropspectionSearchContext.Provider value={{ portal, states, modality, filters, handleFilters, handleApiModality }}>
      {children}
    </PropspectionSearchContext.Provider>
  )
}

const usePropspectionSearch = () => {
  return useContext(PropspectionSearchContext)
}

export { PropspectionSearchProvider, usePropspectionSearch }
