import { createContext, PropsWithChildren, useContext, useState } from 'react'

const LoadingContext = createContext<any>(null)

const LoadingProvider = ({ children }: PropsWithChildren) => {
  const [active, setAtive] = useState<boolean>(false)

  const openLoading = () => {
    setAtive(true)
  }

  const closeLoading = () => {
    setAtive(false)
  }

  return <LoadingContext.Provider value={{ active, openLoading, closeLoading }}>{children}</LoadingContext.Provider>
}

const useLoading = () => {
  return useContext(LoadingContext)
}

export { LoadingProvider, useLoading }
