import { createContext, PropsWithChildren, useEffect, useState } from 'react'
import { defaultsHeadersAxios } from 'services/api'
import { UserTypes } from 'types/userTypes'
import { TOKEN_KEY } from 'utils/constants'
import { getItem } from 'utils/persistenceUtils'
import useUserConfig from 'utils/useUserConfig'

export const UserContext = createContext<any>(null)

export const UserProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<UserTypes>()
  const { getUserStorage } = useUserConfig()

  useEffect(() => {
    const token = getItem(TOKEN_KEY)
    if (token) {
      defaultsHeadersAxios(token)
      const users = getUserStorage()
      setUser(users)
    }
  }, [])

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>
}
