import { format, fromUnixTime } from 'date-fns'

export const formatDateTimeStamp = (date: number) => {
  return date ? format(fromUnixTime(date), 'dd/MM/yy HH:mm') : '------------------'
}

export const formatDateTimeStampSecond = (date: number) => {
  return date ? format(fromUnixTime(date), 'dd/MM/yy HH:mm:ss') : '------------------'
}

export const formatDateTimeStampAs = (date: number) => {
  return date ? format(fromUnixTime(date), 'dd/MM/yy HH:mm') : '------------------'
}

export const formatDateTimeStampAsX = (date: number) => {
  return date ? format(fromUnixTime(date), 'dd/MM/yy ') : '------------------'
}

export const formatDate = (date: number) => {
  return date ? format(fromUnixTime(date), 'dd/MM/yy') : '------------------'
}

export const formatDateToCalc = (date: number) => {
  return date ? format(fromUnixTime(date), 'MM-dd-yyyy') : '------------------'
}

export const formatDateBr = (date: any) => {
  return date ? format(date, 'dd/MM/yyyy') : '------------------'
}
