import { debounce } from 'lodash'
import { createContext, PropsWithChildren, useContext, useEffect, useRef } from 'react'

const MonitoringFirebaseContext = createContext<any>(null)

const MonitoringFirebaseProvider = ({ children }: PropsWithChildren) => {
  const isMonitoringRef = useRef(true) // Usar useRef para guardar o estado
  const checkIsMonitoringRef = useRef(false) // Usar useRef para guardar o estado

  const handleIsMonitoring = () => {
    checkIsMonitoringRef.current = true // Atualizar o valor de checkIsMonitoring via useRef
    isMonitoringRef.current = true // Atualizar o valor de isMonitoring via useRef
  }

  const checkAndSetState = debounce(() => {
    if (!checkIsMonitoringRef.current) {
      isMonitoringRef.current = false // Atualizar o valor de isMonitoring via useRef
    } else {
      isMonitoringRef.current = true // Atualizar o valor de isMonitoring via useRef
    }
    checkIsMonitoringRef.current = false // Atualizar o valor de checkIsMonitoring via useRef
  }, 70000) // 60.000 ms = 1 minuto

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkIsMonitoringRef.current = true // Atualizar o valor de checkIsMonitoring via useRef
        isMonitoringRef.current = true // Atualizar o valor de isMonitoring via useRef
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    checkAndSetState()

    return () => {
      checkAndSetState.cancel() // Cancela o debounce se o componente for desmontado antes dele ser executado
    }
  }, []) // Não há dependências

  return (
    <MonitoringFirebaseContext.Provider value={{ isMonitoring: isMonitoringRef.current, handleIsMonitoring }}>
      {children}
    </MonitoringFirebaseContext.Provider>
  )
}

const useMonitoringFirebase = () => {
  return useContext(MonitoringFirebaseContext)
}

export { MonitoringFirebaseProvider, useMonitoringFirebase }
