import { useState } from 'react'

export default (isValue = false) => {
  const [isOpen, setIsOpen] = useState<boolean>(isValue)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const openToggle = () => {
    setIsOpen(true)
  }

  const closeToggle = () => {
    setIsOpen(false)
  }

  return { isOpen, handleToggle, openToggle, closeToggle }
}
