import React, { createContext, PropsWithChildren, useContext, useState } from 'react'
import { BiChevronDownCircle, BiErrorAlt } from 'react-icons/bi'
import { FiAlertCircle } from 'react-icons/fi'
import { RiFileWord2Line, RiFireFill, RiPulseFill } from 'react-icons/ri'
import { toast } from 'react-toastify'

interface ToastContextData {
  toastError(title: string, description: string): void

  toastSuccess(title: string, description: string): void

  toastInfo(title: string, description: string): void

  toastMessageTypeCompany(company: string, description: string, portal: string): void

  toastMessageTypeKeyWords(company: string, description: string, portal: string): void

  toastMessageTypeChangeBidding(company: string, description: string, portal: string): void
}

interface ToastMessage {
  id: string
  type: 'success' | 'error' | 'info' | 'warning'
  title?: string
  description?: string
  icon?: React.ReactNode
  company?: string
  portal?: string
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData)

const ToastProvider = ({ children }: PropsWithChildren) => {
  const [messages, setMessage] = useState<ToastMessage[]>([])

  const contentToast = ({ title, description, portal }: any) => {
    return (
      <div className="contentToast">
        <strong>{title}</strong>
        {!!description && <p>{description}</p>}
        {!!portal && (
          <p>
            <b>{portal}</b>
          </p>
        )}
      </div>
    )
  }

  const toastSuccess = (title: string, description: string) => {
    toast.success(contentToast({ title, description }), {
      icon: <BiChevronDownCircle />,
    })
  }

  const toastError = (title: string, description: string) => {
    toast.error(contentToast({ title, description }), {
      icon: <BiErrorAlt />,
    })
  }
  const toastInfo = (title: string, description: string) => {
    toast.info(contentToast({ title, description }), {
      icon: <FiAlertCircle />,
    })
  }

  const toastMessageTypeCompany = (company: string, description: string, portal: string) => {
    toast.warning(contentToast({ title: company, description, portal }), {
      icon: <RiFireFill />,
    })
  }
  const toastMessageTypeKeyWords = (company: string, description: string, portal: string) => {
    toast.info(contentToast({ title: company, description, portal }), {
      icon: <RiFileWord2Line />,
    })
  }
  const toastMessageTypeChangeBidding = (company: string, description: string, portal: string) => {
    toast.info(contentToast({ title: company, description, portal }), {
      icon: <RiPulseFill />,
    })
  }

  return (
    <ToastContext.Provider
      value={{
        toastError,
        toastSuccess,
        toastInfo,
        toastMessageTypeCompany,
        toastMessageTypeKeyWords,
        toastMessageTypeChangeBidding,
      }}
    >
      {children}
    </ToastContext.Provider>
  )
}

const useToast = () => {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error('error useToast')
  }
  return context
}

export { ToastProvider, useToast }
