import { useEffect, useState } from 'react'
import { USER_CONFIG } from './constants'

interface DataLogin {
  email: string
  is_client_admin: boolean
  is_client_analyst: boolean
  name: string
  token: string
  username: string
}

export default () => {
  const [userName, setUserName] = useState<string>('')
  const [emailTo, setEmail] = useState<string>('')
  const [userConfigData, setUserConfigData] = useState({ first_name: '', last_name: '', phone: '' })
  const [isClientAdmin, setIsClientAdmin] = useState<boolean>(false)

  const setUserStorage = (data: DataLogin) => {
    localStorage.setItem(USER_CONFIG, JSON.stringify(data))
  }
  const getUserStorage = () => JSON.parse(<string>localStorage.getItem(USER_CONFIG))

  useEffect(() => {
    if (getUserStorage()) {
      const { name, is_client_admin, email, first_name, last_name, phone } = getUserStorage()
      setIsClientAdmin(is_client_admin)
      setUserConfigData({ first_name, last_name, phone })
      setUserName(name)
      setEmail(email)
    }
  }, [])

  return { userConfigData, setUserStorage, getUserStorage, userName, isClientAdmin, emailTo }
}
