import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../services/api'
import { useToast } from './useToast'

const ProposalContext = createContext<any>(null)

const ProposalProvider = ({ children }: PropsWithChildren) => {
  const [proposal, setProposal] = useState<any>({})
  const [sendSheet, setSendSheet] = useState<any>(false)
  const [steep, setSteep] = useState(1)
  const [sheetId, setSheetId] = useState<any>(null)
  const [isValidDocument, setIsValidDocument] = useState(false)
  const [finallyModal, setFinallyModal] = useState(false)
  const [filesProposta, setFilesProposta] = useState<any>([])
  const [loadingCadastroProposta, setLoadingCadastroProposta] = useState(false)
  const { toastError, toastSuccess } = useToast()
  const [disabledAction, setDisabledAction] = useState({ previus: false, next: false })

  const clearState = () => {
    setProposal({})
    setSendSheet(false)
    setSteep(1)
    setSheetId(null)
    setIsValidDocument(false)
    setFinallyModal(false)
    setFilesProposta([])
    setLoadingCadastroProposta(false)
    setDisabledAction({ previus: false, next: false })
  }

  const handleChangeStep = ({ direction, error }: { direction: 'previus' | 'next'; error: boolean }) => {
    setDisabledAction({ ...disabledAction, [direction]: error })
  }

  const handleSteep = (steep: any) => {
    setSteep(steep)
  }

  const handelSendSheet = () => {
    setSendSheet(true)
  }
  const handelSendSheetClose = () => {
    setSendSheet(false)
  }

  const getProposal = async (sheetId: any) => {
    try {
      const { data } = await api.get(`proposal/proposal/${sheetId}`)
      setProposal(data.proposal)
    } catch (e) {
      console.log(e)
    }
  }

  const startProposal = async (sheetId: any) => {
    setLoadingCadastroProposta(true)

    try {
      await api.get(`/proposal/start_upload_new_law/${sheetId}`)
      setFinallyModal(true)
      toastSuccess('Proposta iniciada com sucesso!', 'Agora você acompanhar o andamento da proposta')
    } catch (e) {
      setFinallyModal(false)
      toastError('Erro ao iniciar proposta', 'Tente novamente mais tarde!')
    }
    setLoadingCadastroProposta(false)
  }

  const idSheet = useParams() as any
  const getAllFiles = async () => {
    const idWind = window.location.pathname.split('/')[2]
    const idSheets = idWind || idSheet?.sheetId || sheetId
    try {
      const { data } = await api.get(`/proposal/all/files/${idSheets}`)
      const documentProposal = data?.proposal_files?.filter((item: any) => item?.for_proposal)
      const documentQualification = data?.proposal_files?.filter((item: any) => item?.for_qualification)
      setFilesProposta({ documentQualification, documentProposal })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ProposalContext.Provider
      value={{
        setDisabledAction,
        disabledAction,
        handleChangeStep,
        proposal,
        setProposal,
        sendSheet,
        handelSendSheet,
        handelSendSheetClose,
        steep,
        handleSteep,
        setSheetId,
        startProposal,
        finallyModal,
        setFinallyModal,
        isValidDocument,
        setIsValidDocument,
        getAllFiles,
        filesProposta,
        clearState,
        loadingCadastroProposta,
        getProposal,
      }}
    >
      {children}
    </ProposalContext.Provider>
  )
}

const useProposal = () => {
  return useContext(ProposalContext)
}

export { ProposalProvider, useProposal }
