import { createGlobalStyle } from 'styled-components'
import { theme } from 'wave-design-system-plus'

export default createGlobalStyle`

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  body{
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
      font-family: 'Nunito Sans', sans-serif;

  }
  
  .rdt_TableBody {
    max-height: calc(100vh - 240px) !important;
  }
  

.Toastify__toast {

  .Toastify__toast-icon {
    svg {
      width: 17px;
      height: 17px;
    }
  }

  &.Toastify__toast--success {
  background: ${theme.colors.mint10};
  color: ${theme.colors.mint40};
    .Toastify__close-button {
    color: ${theme.colors.mint40};
    }
  }

  &.Toastify__toast--error {
  background: ${theme.colors.red10};
  color: ${theme.colors.red40};
    .Toastify__close-button {
    color: ${theme.colors.red40};
    }
  }

  &.Toastify__toast--info {
  background: ${theme.colors.cyan10};
  color: ${theme.colors.cyan30};
    .Toastify__close-button {
    color: ${theme.colors.cyan30};
    }
  }

  &.Toastify__toast--warning {
  background: ${theme.colors.honey20};
  color: ${theme.colors.honey40};
    .Toastify__close-button {
    color: ${theme.colors.honey40};
    }

    .Toastify__progress-bar {
      background: ${theme.colors.honey40};
    }
  }

}


.Toastify__toast-theme--colored.Toastify__toast--error {
  svg {
    color: yellow;
  }
}

//input[type=number] {
//    -moz-appearance:textfield;
//}
//
//input::-webkit-outer-spin-button,
//input::-webkit-inner-spin-button {
//
//    -webkit-appearance: none;
//    margin: 0;
//}
`
