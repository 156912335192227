import { createContext, PropsWithChildren, useContext } from 'react'
import useToggle from './useToggle'

const CenterNotificationContext = createContext<any>(null)

const CenterNotificationProvider = ({ children }: PropsWithChildren) => {
  const { isOpen: badge, openToggle: openBadge, closeToggle: closeBadge } = useToggle(true)

  return (
    <CenterNotificationContext.Provider value={{ badge, openBadge, closeBadge }}>
      {children}
    </CenterNotificationContext.Provider>
  )
}

const useCenterNotification = () => {
  return useContext(CenterNotificationContext)
}

export { CenterNotificationProvider, useCenterNotification }
