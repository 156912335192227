import notFoundVideo from 'assets/notFound.png'
import { Container } from './styles'

const Page404 = () => {
  return (
    <Container>
      <div className="not-found-video">
        <img src={notFoundVideo} alt="animação de erro" />
      </div>
      <div className="text-box">
        <h2>Página não encontrada</h2>
        <span>
          <a href="/painel_dashboard">voltar para home</a>
        </span>
      </div>
    </Container>
  )
}

export default Page404
