import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import api from '../services/api'
import { useCompanies } from './useCompanies'

const SettingsProspectionContext = createContext<any>(null)

const SettingsProspectionProvider = ({ children }: PropsWithChildren) => {
  const [loading, setLoading] = useState(true)
  const [settingProspection, setSettingProspection] = useState<any>([])
  const { company } = useCompanies()
  const [isValidate, setIsValidate] = useState(true)

  const getSettingProspection = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await api.get(`prospection/bulletin/settings/company/${company?.id}`)
      if (data.length > 0) setSettingProspection(data.pop())
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [company?.id, setSettingProspection, setLoading])

  const handleApiBoletinUpdate = useCallback(async () => {
    try {
      const { data } = await api.get(`prospection/bulletin/settings/company/${company.id}`)
      setSettingProspection(data.pop())
    } catch (e) {
      console.log(e)
    }
  }, [company?.id, setSettingProspection])

  const updateSettingBoletin = useCallback(
    async (data: any) => {
      const { id } = settingProspection
      try {
        await api.patch(`prospection/bulletin/settings/update/${id}`, { ...data })
        await handleApiBoletinUpdate()
        setIsValidate(true)
      } catch (e) {
        setIsValidate(false)
      }
    },
    [settingProspection, handleApiBoletinUpdate, setIsValidate]
  )

  useEffect(() => {
    if (company) {
      getSettingProspection()
    }
  }, [company, getSettingProspection])

  return (
    <SettingsProspectionContext.Provider
      value={{ isValidate, settingProspection, loading, updateSettingBoletin, getSettingProspection }}
    >
      {children}
    </SettingsProspectionContext.Provider>
  )
}

const useSettingsProspection = () => {
  return useContext(SettingsProspectionContext)
}

export { SettingsProspectionProvider, useSettingsProspection }
