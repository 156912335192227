import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import api from 'services/api'
import handleMessageError from '../utils/handleMessageError'
import { useMonitoringBidding } from './useMonitoringBidding'
import { useToast } from './useToast'
import useToggle from './useToggle'

const MessageBiddingContext = createContext<any>(null)

const MessageBiddingProvider = ({ children }: PropsWithChildren) => {
  const { selectedBidding, handleChangeStatus, biddingFirebase, reloadMonitoring } = useMonitoringBidding()
  const { toastError } = useToast()
  const { isOpen: isFetching, openToggle, closeToggle } = useToggle()
  const { isOpen: loading, openToggle: openToggleLoading, closeToggle: closeToggleLoading } = useToggle()
  const [messages, setMessage] = useState<any>([])
  const [messagesCount, setMessageCount] = useState<number>(0)
  const [messagesCountMatched, setMessageCountMatched] = useState<number>(0)
  const [messagesMatched, setMessagesMatched] = useState<any>([])
  const [totalPages, setTotalPages] = useState<number>(0)

  const handleReadAll = async () => {
    setMessage((msg: any) =>
      msg.map((el: any) => {
        return el.keyword_matched ? { ...el } : { ...el, is_read: true }
      })
    )
  }
  const resetMessages = () => {
    getMessageBidding(0)
    getMessageBiddingMatched()
    setMessage([])
    setMessagesMatched([])
    setMessageCountMatched(0)
    setMessageCount(0)
  }
  useEffect(() => {
    if (selectedBidding) {
      resetMessages()
    }
  }, [selectedBidding])

  useEffect(() => {
    if (selectedBidding && biddingFirebase?.some((bid: any) => bid === selectedBidding.id)) {
      resetMessages()
    }
  }, [biddingFirebase])

  const handleEndMessageRead = async () => {
    await handleReadAll()
    await updateMessageBidding()
    openToggleLoading()
  }

  const getMessageBidding = async (page: number) => {
    if (page === 0) {
      openToggle()
    } else {
      await handleReadAll()
      await updateMessageBidding()
      openToggleLoading()
    }
    if (page > totalPages) {
      await handleReadAll()
      await updateMessageBidding()
      openToggleLoading()
    }

    try {
      const { data } = await api.get(`monitoring/messages/${selectedBidding.id}?page=${page}`)
      if (page === 0) {
        setMessageCountMatched(data.total_matched_messages)
        setMessageCount(data.total_messages)
        setTotalPages(Math.ceil(data.total_messages / 20))
        setMessage(data.messages)
      } else {
        setMessage((prev: any) => [...prev, ...data.messages])
      }
    } catch (e: any) {
      toastError('Erro ao buscar messages!', handleMessageError(e))
    } finally {
      closeToggle()
      closeToggleLoading()
    }
  }

  const getMessageBiddingMatched = async () => {
    try {
      const { data } = await api.get(`monitoring/messages/all/matched/${selectedBidding.id}`)
      setMessagesMatched(data)
    } catch (e: any) {
      toastError('Erro ao buscar messages!', handleMessageError(e))
    } finally {
      closeToggle()
    }
  }

  const updateMessageBidding = async () => {
    const messageNotKeywordMatched = await messages.filter(
      (message: any) => !message.keyword_matched && !message.is_read
    )
    const messages_id = await messageNotKeywordMatched.map((msg: any) => msg.id)
    if (!messages_id.length) return
    try {
      await api.patch(`monitoring/messages/mark_message_as_read/${selectedBidding.id}`, { messages_id })
    } catch (e: any) {
      toastError('Erro ao marcar messages como lida!', handleMessageError(e))
    }
  }

  const updateMessageBiddingStateMatched = async (id: number) => {
    const currentmessagesMatched = messagesMatched.map((msg: any) => {
      return msg.id !== id ? { ...msg } : { ...msg, is_read: true }
    })
    const currentmessagesMatchedList = messages.map((msg: any) => {
      return msg.id !== id ? { ...msg } : { ...msg, is_read: true }
    })
    setMessage(currentmessagesMatchedList)
    setMessagesMatched(currentmessagesMatched)
    await updateMessageBiddingMatched(id)
  }

  const updateMessageBiddingMatched = async (id: number) => {
    try {
      await api.patch(`monitoring/messages/mark_message_as_read/${selectedBidding.id}`, { messages_id: [id] })
    } catch (e: any) {
      toastError('Erro ao marcar messages como lida!', handleMessageError(e))
    }
  }

  const updateMessageAllRead = async () => {
    resetMessages()

    try {
      await api.patch(`monitoring/messages/mark_all_message_as_read/${selectedBidding.id}`)
      await getMessageBiddingMatched()
      await setMessageCountMatched(0)
      await getMessageBidding(0)
      handleChangeStatus()
    } catch (e: any) {
      toastError('Erro ao marcar messages como lida!', handleMessageError(e))
    }
  }

  return (
    <MessageBiddingContext.Provider
      value={{
        isFetching,
        loading,
        messages,
        messagesMatched,
        messagesCount,
        messagesCountMatched,
        getMessageBiddingMatched,
        updateMessageBiddingMatched,
        updateMessageBiddingStateMatched,
        getMessageBidding,
        handleEndMessageRead,
        updateMessageAllRead,
        totalPages,
      }}
    >
      {children}
    </MessageBiddingContext.Provider>
  )
}
const useMessageBidding = () => {
  return useContext(MessageBiddingContext)
}

export { MessageBiddingProvider, useMessageBidding }
