import { createContext, PropsWithChildren, useContext, useState } from 'react'
import api from 'services/api'
import { proposalProps } from '../types/propostaTypes'
import { MODALIDADES } from '../utils/constants'
import { formatDateTimeStampAsX } from '../utils/formatDate'
import { useCompanies } from './useCompanies'
import { useToast } from './useToast'

const ProposalListContext = createContext<any>(null)

const ProposalListProvider = ({ children }: PropsWithChildren) => {
  const { company } = useCompanies()
  const [loadingCreate, setLoadingCreate] = useState(false)
  const [status, setStatus] = useState<any[]>([])
  const [generatePropostas, setGeneratePropostas] = useState<proposalProps[]>([])
  const [pages, setPages] = useState(1)
  const [finishPages, setFinishPages] = useState(1)
  const [finishTotal, setFinishTotal] = useState(0)
  const [total, setTotal] = useState(0)
  const [propostasFinished, setPropostasFinished] = useState<proposalProps[]>([])
  const [loading, setLoading] = useState(false)
  const [totalFinishCreate, setTotalFinishCreate] = useState(0)
  const [pagesFinishCreate, setPagesFinishCreate] = useState(1)
  const [propostasFinishedCreated, setPropostasFinishedCreated] = useState<proposalProps[]>([])
  const [loadingFinishCreate, setloadingFinishCreate] = useState(false)
  const [modalidade, setModalidade] = useState<any>({ id: 5, value: 'Pregão Eletrônico' })
  const [modalErrorPortal, setModalErrorPortal] = useState(false)

  const createProposal = async ({ bid_code, buyer_code, modality }: any) => {
    setLoadingCreate(true)
    try {
      const { data } = (await api.post('/proposal/create/new_law', {
        company: company?.id,
        bid_code,
        buyer_code,
        modality: MODALIDADES.find(item => item.id === modality)?.id || '--------',
      })) as any
      setGeneratePropostas([data, ...generatePropostas])
    } catch (data: any) {
      setModalErrorPortal(data?.response?.status === 403)
      toastError('Não foi possivel gerar planilha!', 'Veriquei se ja existe planilha gerada com esse dados')
    } finally {
      setLoadingCreate(false)
    }
  }

  const deleteProposalList = (id: number) => {
    setGeneratePropostas(generatePropostas.filter(proposta => proposta?.id !== id))
  }

  const filterProposalPortal = (id: number) => {
    setGeneratePropostas(
      generatePropostas.map(proposta => (proposta?.id === id ? { ...proposta, status: 9 } : proposta))
    )
  }

  const handleChanges = (e: any) => {
    const { id, value } = e
    setLicitation({ ...licitation, [id]: value })
  }

  const [licitation, setLicitation] = useState<any>({
    bid_code: '',
    buyer_code: '',
  })
  const { toastError } = useToast()

  const { bid_code, buyer_code } = licitation

  const handleChangesPropostaFinish = (id: any) => {
    if (propostasFinishedCreated.length > 0) {
      const newProposta = propostasFinishedCreated.map((proposta: any) => {
        if (proposta.id === id) {
          return {
            ...proposta,
            status: 9,
          }
        }
        return proposta
      })
      setPropostasFinishedCreated(newProposta)
    }
    setPropostasFinished(
      propostasFinished.map((proposta: any) => (proposta.id === id ? { ...proposta, status: 9 } : proposta))
    )
  }

  const filterProposalFinished = (proposta: proposalProps[]) => {
    return proposta.filter(proposta => {
      return proposta.finished
    })
  }

  const filterProposal = (id: number) => {
    setGeneratePropostas(generatePropostas.filter(proposta => proposta?.id !== id))
    setPropostasFinished(propostasFinished.filter(proposta => proposta?.id !== id))
  }

  const getListProposalFinish = async (loading?: boolean) => {
    setLoading(loading || false)
    try {
      const {
        data: { proposals, total },
      } = await api.get(`/proposal/all/by_company/finished/${company?.id}?page=${finishPages - 1}`)
      setPropostasFinished(filterProposalFinished(proposals))
      if (finishPages === 1) {
        setFinishTotal(total)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getListProposal = async (loading?: boolean) => {
    setLoading(loading || false)
    try {
      const {
        data: { proposals, total },
      } = await api.get(`/proposal/all/by_company/${company?.id}?page=${pages - 1}`)
      setGeneratePropostas(
        proposals.map((proposta: any) => ({
          ...proposta,
          modality: MODALIDADES.find(item => item.id.toString() === proposta.modality)?.value || '----------------',
          dispute_date_timestamp: proposta.dispute_date,
          dispute_date: formatDateTimeStampAsX(proposta.dispute_date),
        }))
      )
      if (pages === 1 && total) {
        setTotal(total)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getAllStatusProposal = async () => {
    try {
      const { data } = await api.get(`/proposal/all/status`)
      setStatus(data)
    } catch (error) {
      console.log(error)
    }
  }

  const getListProposalFinishCadastradas = async () => {
    setloadingFinishCreate(true)
    try {
      const {
        data: { proposals, total },
      } = await api.get(`/proposal/all/by_company/${company?.id}?page=${pagesFinishCreate - 1}&status_id=7,9,10`)

      if (pagesFinishCreate === 1) {
        setTotalFinishCreate(total)
      }
      setPropostasFinishedCreated(proposals)
    } catch (error) {
      console.log(error)
    } finally {
      setloadingFinishCreate(false)
    }
  }

  const getAllProposal = async () => {
    await getListProposal()
    await getAllStatusProposal()
    await getListProposalFinish()
    await getListProposalFinishCadastradas()
  }

  const createProposals = async () => {
    setLoadingCreate(true)
    try {
      const { data } = (await api.post('/proposal/create/new_law', {
        company: company?.id,
        bid_code,
        buyer_code,
        modality: modalidade.id,
      })) as any
      setLicitation({ bid_code: '', buyer_code: '' })
      setGeneratePropostas([data, ...generatePropostas])
    } catch (data: any) {
      setModalErrorPortal(data?.response?.status === 403)
      toastError('Não foi possivel gerar planilha!', 'Veriquei se ja existe planilha gerada com esse dados')
    } finally {
      setLoadingCreate(false)
    }
  }

  return (
    <ProposalListContext.Provider
      value={{
        bid_code,
        handleChanges,
        buyer_code,
        createProposal,
        loadingCreate,
        generatePropostas,
        propostasFinished,
        getAllProposal,
        total,
        pages,
        setPages,
        finishTotal,
        finishPages,
        setFinishPages,
        getListProposalFinish,
        getListProposal,
        filterProposal,
        filterProposalPortal,
        status,
        loading,
        getListProposalFinishCadastradas,
        totalFinishCreate,
        propostasFinishedCreated,
        pagesFinishCreate,
        setPagesFinishCreate,
        loadingFinishCreate,
        handleChangesPropostaFinish,
        setModalidade,
        modalidade,
        modalErrorPortal,
        getAllStatusProposal,
        setModalErrorPortal,
        deleteProposalList,
      }}
    >
      {children}
    </ProposalListContext.Provider>
  )
}

const useProposalList = () => {
  return useContext(ProposalListContext)
}

export { ProposalListProvider, useProposalList }
