export const MockDeclarationCheckList = [
  {
    id: 1,
    checked: false,
    text: 'Senhor fornecedor, assinale SIM para a declaração abaixo, caso seja Microempresa, Empresa de Pequeno Porte ou Cooperativa e deseje usufruir do tratamento estabelecido nos artigos 42 ao 49 da Lei complementar 123 de 14 de dezembro de 2006 para esse pregão eletrônico, caso contrário assinale NÃO. Declaro, sob as penas da Lei, que não ultrapassei o limite de faturamento e cumpro os requisitos estabelecidos no Art. 3º da Lei Complementar nº 123, de 14 de dezembro de 2006, sendo apto a usufruir do tratamento favorecido estabelecido nos artigos 42 ao 49 da referida Lei Complementa.',
  },
  {
    id: 2,
    checked: true,
    text: 'Declaro que estou ciente e concordo com as condições contidas no edital e seus anexos, bem como de que cumpro plenamente os requisitos de habilitação definidos no edital.',
    disabled: true,
  },
  {
    id: 3,
    checked: false,
    text: 'Declaro sob as penas da lei, que até a presente data inexistem fatos impeditivos para a minha habilitação no presente processo licitatório, ciente da obrigatoriedade de declarar ocorrências posteriores.',
  },
  {
    id: 4,
    checked: false,
    text: 'Declaro para fins do disposto no inciso V do art. 27 da Lei nº 8.666, de 21 de junho de 1993, acrescido pela Lei nº 9.854, de 27 de outubro de 1999, que não emprego menor de 18 (dezoito) anos em trabalho noturno, perigoso ou insalubre e não emprego menor de 16 (dezesseis) anos, salvo menor, a partir de 14 (quatorze) anos, na condição de aprendiz, nos termos do inciso XXXIII, do art. 7º da Constituição Federal.',
  },
  {
    id: 5,
    checked: false,
    text: 'Declaro que não possuo, em minha cadeia produtiva, empregados executando trabalho degradante ou forçado, observando o disposto nos incisos III e IV do art.1° e no inciso III do art.5º da Constituição Federal.',
  },
  {
    id: 6,
    checked: false,
    text: 'Declaro que, conforme disposto no art. 93 da Lei nº 8.213, de 24 de julho de 1991, estou ciente do cumprimento da reserva de cargos prevista em lei para pessoa com deficiência ou para reabilitado da Previdência Social e que, se aplicado ao número de funcionários da minha empresa, atendo às regras de acessibilidade previstas na legislação.',
  },
  {
    id: 7,
    checked: false,
    text: 'Declaro sob as penas da Lei, que cumpro a cota de aprendizagem nos termos estabelecidos no art. 429 da CLT.',
  },
  {
    id: 8,
    checked: false,
    text: 'Declaro que a proposta apresentada para essa licitação foi elaborada de maneira independente, de acordo com o que é estabelecido na Instrução Normativa N° 2 de 16 de setembro de 2009 da SLTI/MP. Clique aqui para detalhamento dessa declaração.',
  },
]

export const MockInputCompanie = {
  cell_phone: '',
  city: '',
  cnpj: '',
  company: '',
  complement: '',
  corporate_name: '',
  email: '',
  enable: true,
  id: '',
  municipal_registration: '',
  neighborhood: '',
  phone: '',
  state_registration: '',
  street: '',
  street_number: '',
  uf: '',
  website: '',
  zip_code: '',
}
