import { PropsWithChildren, useEffect } from 'react'
import { Navigate, useLocation, useMatch, useNavigate } from 'react-router-dom'
import { defaultsHeadersAxios } from 'services/api'
import { TOKEN_KEY, routes } from 'utils/constants'
import { getItem } from 'utils/persistenceUtils'

const RequireAuth = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const accountValidationMatch = useMatch(routes.ACC_VALIDATION_EMAIL)
  const resetPasswordMatch = useMatch(routes.RESET_PASSWORD)

  useEffect(() => {
    const token = getItem(TOKEN_KEY)
    if (token) {
      defaultsHeadersAxios(token)
    } else if (!accountValidationMatch && !resetPasswordMatch) {
      navigate(routes.LOGIN)
    }
  }, [accountValidationMatch, navigate])

  const auth = getItem(TOKEN_KEY)
  if (!auth) return <Navigate to={routes.LOGIN} />
  return <>{children}</>
}

export default RequireAuth
