const purple = {
  purple40: '#1b38a5',
  purple30: '#1b38a5',
  purple20: '#C099FF',
  purple10: '#EEE3FF',
}

const blue = {
  blue40: '#3a566e',
  blue30: '#516579',
  blue20: '#69788b',
  blue10: '#8c92a7',
}

const shades = {
  shade80: '#262930',
  shade70: '#4A4F58',
  shade60: '#727780',
  shade50: '#ABB0BA',
  shade40: '#CFD2D9',
  shade30: '#E5E7EB',
  shade20: '#F0F1F2',
  shade10: '#FAFAFB',
}

const red = {
  red40: '#D40728',
  red30: '#FF4D6A',
  red20: '#FFBDC8',
  red10: '#FFE3E8',
}

const honey = {
  honey40: '#916B00',
  honey30: '#FFC938',
  honey20: '#FFE7A7',
  honey10: '#FFF8E3',
}

const mint = {
  mint40: '#008262',
  mint30: '#28D2A7',
  mint20: '#A6EDDB',
  mint10: '#DEFAF3',
}

const cyan = {
  cyan40: '#0075B0',
  cyan30: '#49B9F2',
  cyan20: '#B4E3FB',
  cyan10: '#E3F6FF',
}

const white = '#FFFFFF'
const black = '#000000'

const brand = {
  brand10: purple.purple10,
  brand20: purple.purple20,
  brand30: purple.purple30,
  brand40: purple.purple40,
}

const accent = {
  accent40: '#C95100',
  accent30: '#FF6600',
  accent20: '#FFB380',
  accent10: '#FFE9DB',
}

const special = {
  magenta40: '#D1008B',
  magenta30: '#FD38BA',
  magenta20: '#FFADE4',
  magenta10: '#FFE3F6',
}

export const spacings = {
  xxxxSmall: '4px',
  xxxSmall: '8px',
  xxSmall: '16px',
  xSmall: '24px',
  small: '32px',
  medium: '40px',
  large: '48px',
  xLarge: '64px',
  xxLarge: '80px',
}

export const typography = {
  fontWeight: {
    bold: 700,
    regular: 400,
  },
  text: {
    big: '3em',
    normal: '2em',
    small: '1em',
    xSmall: '0.8em',
  },
  heading: {
    big: '36px',
    normal: '25px',
    small: '20px',
  },
}

const exportShadows = (color: string) => {
  /* eslint-disable */
  const small = `0 1px 0 0 ${color}0D, 0 2px 4px 0 ${color}0D, 0 4px 8px 0 ${color}0D`
  /* eslint-enable */
  const medium = `0 8px 16px 0 ${color}0D, 0 12px 24px 0 ${color}0D`

  return {
    small,
    medium: `${small}, ${medium}`,
    large: `${small}, ${medium}, 0 16px 32px 0 ${color}0D`,
    xSmall: `0 2px 0 0 ${color}1A`,
    xSmallTop: `0 -2px 0 0 ${color}1A`,
    xSmallLeft: `-2px 0 0 0 ${color}1A`,
    xSmallRight: `2px 0 0 0 ${color}1A`,
  }
}

export const shadows = {
  default: exportShadows(shades.shade60),
  informative: exportShadows(cyan.cyan40),
  attention: exportShadows(honey.honey40),
  negative: exportShadows(red.red40),
  positive: exportShadows(mint.mint40),
  highlight: exportShadows(brand.brand40),
  border: exportShadows,
}

export const theme = {
  spacings,
  shadows,
  typography,
  colors: {
    ...blue,
    ...shades,
    ...purple,
    ...red,
    ...honey,
    ...mint,
    ...cyan,
    ...brand,
    ...accent,
    ...special,
    white,
    black,
  },
  fonts: ['sans-serif', 'Roboto'],
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em',
    t0: '10px',
    t1: '14px',
    t2: '16px',
    t3: '20px',
    t4: '2em',
    t5: '3em',
  },
}
