import { PropsWithChildren, createContext, useContext, useEffect, useState, useCallback } from 'react'
import api from 'services/api'
import { Settings } from 'types/settingsType'
import handleMessageError from '../utils/handleMessageError'
import { useCompanies } from './useCompanies'
import { useToast } from './useToast'

interface SettingMonitoringContextProps {
  settings: Settings[]
  getSettingsNotification: () => Promise<void>
  handleChange: (value: Settings) => Promise<void>
  filterTypeNotification: (notification_type: number) => Settings | undefined
}

const SettingMonitoringContext = createContext<SettingMonitoringContextProps>({} as SettingMonitoringContextProps)

interface SettingsRequest {
  data: Settings[]
}

const SettingMonitoringProvider = ({ children }: PropsWithChildren) => {
  const { company } = useCompanies()
  const [settings, setSettings] = useState<Settings[]>([])
  const { toastError } = useToast()

  const getSettingsNotification = async () => {
    try {
      const { data } = (await api.get(`notification/settings`)) as SettingsRequest
      setSettings(data)
    } catch (e: any) {
      toastError('Erro ao buscar configurações do monitoramento.', handleMessageError(e))
    }
  }

  const updateSettingsNotification = async (id: number, newSettings: Settings[]) => {
    const setting = newSettings.find((item: any) => item.id === id)
    try {
      await api.patch(`notification/settings/update/${id}`, setting)
    } catch (e: any) {
      toastError('Erro ao buscar configurações do monitoramento.', handleMessageError(e))
    }
  }

  const handleChange = async (value: Settings) => {
    const newSettings = settings.map((setting: Settings) => {
      if (setting.id === value.id) {
        return value
      }
      return setting
    })
    await updateSettingsNotification(value.id, newSettings)
    setSettings(newSettings)
  }

  const filterTypeNotification = useCallback(
    (notification_type: number | string) => {
      return settings.find(item => item.notification_type.toString() === notification_type.toString())
    },
    [settings]
  )

  useEffect(() => {
    if (company?.id) getSettingsNotification()
  }, [company?.id])

  return (
    <SettingMonitoringContext.Provider
      value={{ settings, getSettingsNotification, handleChange, filterTypeNotification }}
    >
      {children}
    </SettingMonitoringContext.Provider>
  )
}

const useSettingMonitoring = () => {
  return useContext(SettingMonitoringContext)
}

export { SettingMonitoringProvider, useSettingMonitoring }
