import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import api from 'services/api'
import { CompanyTypes } from 'types/companyTypes'
import { useLoading } from './useLoading'
import { useToast } from './useToast'

import { IS_CLIENT_ADMIN, routes, TOKEN_KEY } from '../utils/constants'
import { getItem } from '../utils/persistenceUtils'
import { useCredentials } from './useCredentials'

interface CompaniesResponse {
  operating: number
  companies: CompanyTypes[]
}

const CompanyContext = createContext<any>(null)

const CompanyProvider = ({ children }: PropsWithChildren) => {
  const { toastSuccess, toastError } = useToast()
  const { closeLoading, openLoading } = useLoading()
  const [companies, setCompanies] = useState<CompanyTypes[]>([])
  const [company, setCompany] = useState<CompanyTypes | null>(null)
  const [openModalCreateCompany, setOpenModalCreateCompany] = useState<boolean>(false)
  const [openModalUserAnalystOffCompany, setOpenModalUserAnalystOffCompany] = useState<boolean>(false)
  const [update, setUpdate] = useState<boolean>(false)
  const navigate = useNavigate()
  const isInDetailsRoute = useMatch(routes.BIDS_DETAILS)

  useEffect(() => {
    const isTOKEN_KEY = getItem(TOKEN_KEY)
    if (isTOKEN_KEY) {
      getCompanies()
    }
  }, [])

  const closeModalCreateCompany = () => {
    setOpenModalCreateCompany(false)
  }

  const closeModalUserAnalystOffCompany = () => {
    setOpenModalUserAnalystOffCompany(false)
  }

  const getCompanies = async () => {
    const isClientAdmin = getItem(IS_CLIENT_ADMIN)
    try {
      const { data } = await api.get('management/companies')
      const { companies, operating } = data as CompaniesResponse
      if (companies?.length > 0) {
        const companySelect = companies.find((company: CompanyTypes) => company.id === operating)
        if (!operating) {
          setCompany(companies[0])
        } else {
          setCompany(companySelect || companies[0])
        }
        setCompanies(companies)
        setUpdate(false)
      } else if (isClientAdmin === 'true') {
        setOpenModalCreateCompany(true)
      } else {
        setOpenModalUserAnalystOffCompany(true)
      }
    } catch (e: any) {
      toastError('Erro ao buscar empresas.', '')
    } finally {
      closeLoading()
    }
  }

  const previousCompanyRotaBid = () => {
    if (isInDetailsRoute) navigate(routes.BIDS_LIST)
  }

  const changeCompany = async (dataCompany: CompanyTypes) => {
    previousCompanyRotaBid()
    openLoading()
    try {
      await api.post(`management/company/operating/${dataCompany.id}`)
      const companySelect = companies.find((companyEl: CompanyTypes) => companyEl.id === dataCompany.id)
      setCompany(companySelect || null)
      toastSuccess('Empresa selecionada.', dataCompany.trading_name)
    } catch (e: any) {
      const messageError = Object?.values(e.response.data).toString()
      toastError('Erro ao cadastrar usuario.', messageError)
    } finally {
      setTimeout(() => {
        closeLoading()
      }, 2000)
    }
  }

  const resetState = () => {
    setCompany(null)
    setCompanies([])
  }

  const filterCompanyId = (id: number) => {
    return companies.find(company => company.id === id)
  }

  return (
    <CompanyContext.Provider
      value={{
        companies,
        company,
        update,
        setCompanies,
        setOpenModalCreateCompany,
        closeModalCreateCompany,
        openModalUserAnalystOffCompany,
        closeModalUserAnalystOffCompany,
        openModalCreateCompany,
        changeCompany,
        getCompanies,
        filterCompanyId,
        resetState,
        setUpdate,
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}

const useCompanies = () => {
  const context = useContext(CompanyContext)
  if (!context) {
    throw new Error('useCompanies must be used within a CompanyProvider')
  }
  return context
}

export { CompanyProvider, useCompanies }
