import { useCompanies } from 'hooks/useCompanies'
import { useToast } from 'hooks/useToast'
import useToggle from 'hooks/useToggle'
import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import api from 'services/api'
import { emptyInput, validetValueError } from 'utils/emptyInput'

const initialState = {
  portal: '',
  company: '',
  username: '',
  password: '',
}

interface ContractModule {
  contract: number
  id: number
  module: number
  quota: number
}

interface ContractProps {
  company: number
  created_at: number
  ends_in: number
  id: number
  is_valid: boolean
  modules: ContractModule[]
}

const CredentialsContext = createContext<any>(null)

const CredentialsProvider = ({ children }: PropsWithChildren) => {
  const { company } = useCompanies()
  const [contracts, setContracts] = React.useState<ContractProps>()
  const [validatedCredential, setValidatedCredential] = useState(true)
  const [validatedCredentialEvery, setValidatedCredentialEvery] = useState(true)
  const [invalidContractFree, setInvalidContractFree] = useState(false)
  const [isContractValid, setIsContractValid] = useState(false)
  const [credentials, setCredentials] = useState<any>([])
  const [endsIn, setEndsIn] = useState<Date | undefined>()
  const { toastError } = useToast()
  const [inputValue, setInputValue] = useState({ ...initialState, company: company?.id })
  const { isOpen: isFetching, openToggle, closeToggle } = useToggle()
  const [error, setError] = useState<any>(initialState)
  const [isCredentialComprasNetIsActive, setIsCredentialComprasNetIsActive] = useState(false)

  const validatedCredentialByCompany = () => {
    const validation = credentials?.filter((credentials: any) => credentials.company === company.id)
    const VerifyCredentialsByCompany = validation.every((credential: any) => credential.is_valid)
    const VerifyCredentialsAllCompany = validation.some((credential: any) => credential.is_valid)
    setIsCredentialComprasNetIsActive(validation?.find((credential: any) => credential?.portal === 1)?.is_valid)
    if (validation.length > 0 && VerifyCredentialsAllCompany) {
      setValidatedCredentialEvery(true)
    } else {
      setValidatedCredentialEvery(false)
    }
    if (validation.length > 0 && VerifyCredentialsByCompany) {
      setValidatedCredential(true)
    } else {
      setValidatedCredential(false)
    }
  }
  const getByContract = async () => {
    try {
      const { data } = await api.get(`management/contract/last-created/${company?.id}`)
      setContracts(data)
      setIsContractValid(data?.is_valid)
      setInvalidContractFree(data.modules[0]?.quota === 1 && data.ends_in === null && data?.is_valid)

      if (data.ends_in) {
        setEndsIn(new Date(data.ends_in * 1000))
      } else {
        setEndsIn(undefined)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getByContractById = async (id: any) => {
    try {
      const { data } = await api.get(`management/contract/last-created/${id}`)
      setContracts(data)
      setIsContractValid(data?.is_valid)
      setInvalidContractFree(data.modules[0]?.quota === 1 && data.ends_in === null && data?.is_valid)
      if (data.ends_in) {
        setEndsIn(new Date(data.ends_in * 1000))
      } else {
        setEndsIn(undefined)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (company?.id) {
      getByContract()
      getCredentials()
    }
  }, [company?.id])

  const resetInputValue = () => {
    setInputValue({ ...initialState, company: company?.id })
  }

  useEffect(() => {
    if (credentials) {
      validatedCredentialByCompany()
    }
  }, [credentials])

  const handleValues = async (target: any) => {
    const { id, value } = target
    await setInputValue({ ...inputValue, [id]: value })
  }

  const handleValuesEdit = async (credential: any) => {
    openToggle()
    await setInputValue(credential)
    closeToggle()
  }

  const getCredentials = async () => {
    try {
      openToggle()
      const { data } = (await api.get('monitoring/credential/client')) as any
      setCredentials(data)
    } catch (e: any) {
      const messageError = Object?.values(e?.response?.data)?.toString()
      toastError('Erro ao cadastrar empresa', messageError)
    } finally {
      closeToggle()
    }
  }

  const updateCredentials = async () => {
    const { id, credentials } = inputValue as any
    try {
      await api.patch(`monitoring/credential/client/${id}`, { credentials })
      await getCredentials()
    } catch (e: any) {
      const messageError = Object?.values(e?.response?.data).toString()
      toastError('Erro ao cadastrar empresa', messageError)
    }
  }

  const createCredential = async () => {
    try {
      await api.post('monitoring/credential/client', inputValue)
    } catch (e: any) {
      const messageError = Object.values(e.response.data).toString()
      toastError('Erro ao cadastrar empresa', messageError)
    }
  }

  const handleMessageError = (key: any) => {
    setError((prev: any) => ({ ...prev, [key]: false }))
  }

  const validateValue = () => {
    setError(validetValueError(inputValue))
    const status = emptyInput(inputValue) as boolean
    if (!status) {
      toastError('Por favor!', 'Preencha os campos em vermelho')
    }
    return status
  }

  return (
    <CredentialsContext.Provider
      value={{
        isFetching,
        updateCredentials,
        validatedCredential,
        validatedCredentialEvery,
        credentials,
        createCredential,
        getCredentials,
        handleMessageError,
        handleValues,
        handleValuesEdit,
        resetInputValue,
        validateValue,
        getByContractById,
        isCredentialComprasNetIsActive,
        error,
        inputValue,
        contracts,
        invalidContractFree,
        isContractValid,
        endsIn,
      }}
    >
      {children}
    </CredentialsContext.Provider>
  )
}

const useCredentials = () => {
  return useContext(CredentialsContext)
}

export { CredentialsProvider, useCredentials }
