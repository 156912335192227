import Page404 from 'pages/Errors/404'
import { lazy, Suspense } from 'react'
import { Helmet } from 'react-helmet'
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStyles from 'styles/global'
import Theme from 'styles/themes'
import { routes } from 'utils/constants'
import { Loading } from 'wave-design-system-plus'
import Providers from './context'
import RequireAuth from './routes/RequireAuth'

const AppsSuport = lazy(() => import('pages/AppsSuport'))
const Bidding = lazy(() => import('pages/Bidding'))
const ContractByCompany = lazy(() => import('pages/ContractByCompany'))
const Dashboard = lazy(() => import('pages/Dashboard'))
const DetailsBidding = lazy(() => import('pages/DetailsBidding'))
const Monitoring = lazy(() => import('pages/Monitoring'))
const MonitoringOccurrenceDay = lazy(() => import('pages/MonitoringOccurrenceDay'))
const MonitoringSettings = lazy(() => import('pages/MonitoringSettings'))
const RegistrationProposal = lazy(() => import('pages/ProposalNew/RegistrationProposal'))
const ProposalDetails = lazy(() => import('pages/Proposal/SeachBiddingProposal'))
const ProposalProgress = lazy(() => import('pages/ProposalNew/ProposalDetails'))
const ProposalSettings = lazy(() => import('pages/ProposalSettings'))
const CreatedProposal = lazy(() => import('pages/ProposalNew/ProposalCreated'))
const ProspectonSearch = lazy(() => import('pages/PropspectionSearch'))
const Prospection = lazy(() => import('pages/Prospection'))
const ProspectionFavorites = lazy(() => import('pages/ProspectionFavorites'))
const ProspectionSettings = lazy(() => import('pages/ProspectionSettings'))
const Settings = lazy(() => import('pages/Settings'))
const Layout = lazy(() => import('components/Layout'))

const Login = lazy(() => import('pages/Login'))
const ResetPassword = lazy(() => import('pages/ResetPassword/SendToken'))
const ChangePassword = lazy(() => import('pages/ResetPassword/ChangePassword'))
const Register = lazy(() => import('pages/Register'))
const AccountValidation = lazy(() => import('pages/AccountValidation'))
const Logout = lazy(() => import('pages/Logout'))
const AcessesToken = lazy(() => import('pages/AcessesToken'))

const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={
          <Providers>
            <Suspense fallback={<Loading active />}>
              <Outlet />
            </Suspense>
          </Providers>
        }
      >
        <Route path={routes.LOGIN} element={<Login />} />
        <Route path={routes.SEND_TOKEN} element={<ResetPassword />} />
        <Route path={routes.RESET_PASSWORD} element={<ChangePassword />} />
        <Route path={routes.REGISTER} element={<Register />} />
        <Route path={routes.ACC_VALIDATION_EMAIL} element={<AccountValidation />} />
        <Route path={routes.LOGOUT} element={<Logout />} />
        <Route path={routes.ACESSES_TOKEN} element={<AcessesToken />} />
        <Route
          element={
            <RequireAuth>
              <Theme>
                <Suspense fallback={<Loading active />}>
                  <Layout>
                    <Suspense fallback={<Loading active />}>
                      <Outlet />
                    </Suspense>
                  </Layout>
                </Suspense>
              </Theme>
            </RequireAuth>
          }
        >
          <Route path={routes.DASHBOARD} element={<Dashboard />} />

          <Route path={routes.PROSPECTS_LIST} element={<Prospection />} />
          <Route path={routes.PROSPECTS_FAVORITES} element={<ProspectionFavorites />} />
          <Route path={routes.PROSPECTS_SEARCH} element={<ProspectonSearch />} />
          <Route path={routes.PROSPECTS_SETTINGS} element={<ProspectionSettings />} />

          <Route path={routes.BIDS_LIST} element={<Bidding />} />
          <Route path={routes.BIDS_DETAILS} element={<DetailsBidding />} />

          <Route path={routes.PROPOSALS_LIST} element={<RegistrationProposal />} />
          <Route path={routes.PROPOSALS_DETAILS} element={<ProposalProgress />} />
          <Route path={routes.PROPOSALS_HISTORY} element={<ProposalProgress />} />
          <Route path={routes.PROPOSALS_CREATED} element={<CreatedProposal />} />
          <Route path={routes.PROPOSALS_SETTINGS} element={<ProposalSettings />} />

          <Route path={routes.MONITORING_CHAT} element={<Monitoring />} />
          <Route path={routes.MONITORING_OCCURRENCES} element={<MonitoringOccurrenceDay />} />
          <Route path={routes.MONITORING_SETTINGS} element={<MonitoringSettings />} />

          <Route path={routes.SETTINGS} element={<Settings />} />

          <Route path={routes.CONTRACTS} element={<ContractByCompany />} />
          <Route path={routes.SUPORT_APPS} element={<AppsSuport />} />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Route>
    )
  )

  return (
    <>
      <Helmet>
        <script src="https://j24zv21x8hgn.statuspage.io/embed/script.js" crossOrigin="anonymous" async />
      </Helmet>
      <GlobalStyles />
      <RouterProvider router={router} />
    </>
  )
}

export default App
